import { Route, Routes } from "react-router-dom";
import { Suspense, lazy } from "react";
const MainPage = lazy(() => import("./game/main"));
const Xoxoxo = lazy(() => import("./game/xoxoxo"));

function App() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="*" element={<Xoxoxo />} />
      </Routes>
    </Suspense>
  );
}

export default App;
